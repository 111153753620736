import {BrowserRouter, Route, Switch} from "react-router-dom";
import React from "react";
import Home from "../screens/Home";

const Main = () => {

  return (
    <BrowserRouter>
      <Switch>
        <Route path='/'>
          <Home/>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Main
