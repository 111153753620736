import {Box, Grid, makeStyles, Typography} from "@material-ui/core";
import logo from "../assets/logo.png";

const Header=()=>{

  const classes=useStyle()

  return(
    <Box className={classes.headerContainer}>
      <Box>
        SKD Construcciones
      </Box>
      <Box>
        <img className={classes.logo} src={logo} alt={'building'}/>
      </Box>
    </Box>
  )
}

const useStyle=makeStyles(theme=>{
  return {
    headerContainer:{
      padding:theme.spacing(3),
      height:'5rem',
      width:'100%',
      marginBottom:'2rem',
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between'

    },
    logo:{
      width:'8rem',
      height:'4rem',
    }
  }
})

export default Header
