const actions = {
  setDarkMode: 'SET_DARK_MODE'
}

const initialState = {
  isDarkMode:false
}

export default (state=initialState, action)=>{
  if(action.type === actions.setDarkMode) return { ...state, isDarkMode: action.isDarkMode }
  else return state
}

export const setDarkMode = ({dispatch, darkMode})=>{
  dispatch({
    type: actions.setDarkMode,
    isDarkMode: darkMode
  })
}
