import {Box, Grid, makeStyles, Typography} from "@material-ui/core";

const Footer=()=>{

  const classes=useStyle()

  return(
    <Box className={classes.footerContainer}>


      <Box>
        <Typography variant={'h5'}>
          Contacto
        </Typography>
        <Typography variant={'body1'}>
          Av. Dr. José María Vértiz 780, Narvarte Oriente, CDMX.
        </Typography>
        <Typography variant={'body2'}>
          lesqueda@skdsc.mx
        </Typography>
      </Box>

      <Typography variant={'h5'} style={{marginRight:'1rem',marginLeft:'1rem'}}>
        5611774834
      </Typography>

      <Box className={classes.badge}/>

    </Box>
  )
}

const useStyle=makeStyles(theme=>{
  return {
    footerContainer:{
      width:'100%',
      display:'flex',
      alignItems:'flex-end',
      justifyContent:'space-between',
      paddingLeft:12,
      paddingBottom:12

    },
    badge: {
      width: '1rem',
      height: '15vh',
      backgroundColor: 'gray',
    }
  }
})

export default Footer
