import {Box, Hidden, makeStyles, Typography} from "@material-ui/core";
import {Footer, Header} from "../components";
import city from "../assets/cityB.png"
import building from "../assets/build.png"
import buildingB from "../assets/buildB.png"
import buildingC from "../assets/buildC.png"
import buildingD from "../assets/buildD.png"
import quality from "../assets/quality.png"
import plusValue from "../assets/plusValue.png"
import coppel from "../assets/coppel.png"
import bata from "../assets/bata.png"
import pricesshoes from "../assets/priceShoes.png"
import havoc from "../assets/havoc.png"
import gamers from "../assets/gamers.png"
import logo from "../assets/logo.png"

const Home = () => {

  const services =
    [
      'Construcción, Mantenimiento y Remodelaciones',
      'Ingenierias y Proyectos Ejecutivos',
      'Cálculo y dictamenes estructurales',
      'Aire Acondicionado, CCTV, Voz y Datos',
      'Consultoría Supervisión de Obra',
    ]
  const categories =
    [
      ['Proyectos Industriales E Infraestructura', 'Trabajamos en todas las etapas, así como consultores o supervisión de obra', buildingB],
      ['Retail', 'Generamos espacios funcionales y cero mantenimiento en tiempo record.', building],
      ['Residencial', 'Tus ideas las llevamos a la realidad, creando espacios de confort y prácticos.', buildingC],
    ]

  const images=[coppel,bata,pricesshoes,havoc,gamers]

  const classes = useStyles()

  return (
    <Box className={classes.rootContainer}>
      <Box className={classes.backgroundContainer}/>
      <Box className={classes.mainContainer}>
        <Header/>
        <Hidden smUp>
          <Box>
            <img src={logo} alt={'building'}/>
          </Box>
        </Hidden>
        <Box className={classes.sectionsContainer}>



          <Hidden smDown>
            <Box style={{width: '80%'}}>
              <img src={building} alt={'building'}/>
            </Box>
          </Hidden>

          <Box style={{textAlign: 'center'}}>
            <h1>¿Quiénes somos?</h1>
            <Hidden smUp>
              <Box style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Box className={classes.horizontalBadge} style={{height: '.2rem', width: '80%', marginTop: '1rem'}}/>
              </Box>
            </Hidden>
            <Typography style={{fontSize: '1.5em', marginTop: '.5rem',}}>
              Somos una empresa mexicana con más de 20 años de experiencia en el ramo de la Construcción de inmuebles,
              naves industriales, Ingeniería estructural y geotécnica, desarrollo de proyectos industriales, de
              infraestructura por lo que ofrecemos servicios de calidad con la seguridad, bienestar, confort y
              rentabilidad que busca para su negocio.
            </Typography>
          </Box>
          <Hidden smDown>
            <Box className={classes.badge}/>
          </Hidden>
        </Box>
        <Box className={classes.whatWeAreWorkingContainer}>
          <Typography variant={'h5'} style={{textAlign: 'center'}}>
            WHAT ARE WE WORKING ON
          </Typography>
        </Box>
        <Box className={classes.servicesGridContainer}>
          {
            services.map((service, i) =>
              <Box style={{display: 'grid', gridTemplateColumns: '1rem auto', alignItems: "center"}}>
                <Box className={classes.badge} style={{background: `${i % 2 === 0 ? '#9AD7F7' : '#434343'}`}}/>
                <Typography variant={'h5'} style={{textAlign: 'center'}}>
                  {service}
                </Typography>
              </Box>
            )
          }
        </Box>
        <Box className={classes.projectsTypeContainer}>
          {
            categories.map((service, i) =>
              <Box className={classes.projectsTypeItem}>
                <img src={service[2]} alt={'building'} className={classes.buildings}/>
                <Box style={{width: '80%'}}>
                  <Typography variant={'h3'}>
                    {service[0]}
                  </Typography>
                  <Typography variant={'h6'}>
                    {service[1]}
                  </Typography>
                </Box>
              </Box>
            )
          }
        </Box>
        <Box className={classes.cardsContainer}>
          <Box>
            <img src={quality}/>
          </Box>
          <Box>
            <h1>Calidad</h1>
            <Box style={{display: 'flex', alignItems: "center"}}>
              <Typography variant={'h5'}>
                Nuestra política de calidad, nos permite garantizar las fechas de entrega, así como las especificaciones
                técnicas establecidas.
              </Typography>
              <Box className={classes.badge} style={{background: `#9AD7F7`}}/>
            </Box>
          </Box>
        </Box>
        <Box className={classes.cardsContainer}>
          <Box>
            <Typography variant={'h3'}>
              VALOR AGREGADO
            </Typography>
            <Typography variant={'h5'}>
              Nuestra experiencia nos lleva a implementar esquemas constructivos eficaces en tiempos y costos.
            </Typography>
            <Box className={classes.horizontalBadge}/>
          </Box>
          <Box>
            <img src={plusValue}/>
          </Box>

        </Box>
        <Box style={{width: '100%'}}>
          <Box style={{width: '100%', position: 'absolute'}}>
            <Box className={classes.sectionsContainer}
                 style={{width: '100%', flexDirection: 'row-reverse'}}>
              <Box className={classes.slogan}>
                <Box style={{display: 'flex'}}>
                  <Hidden mdUp>
                    <Typography variant={'h5'} style={{fontStyle: 'italic'}} >
                      “Los detalles en la ejecución se reflejan en la calidad y tiempos de los trabajos”.
                    </Typography>
                  </Hidden>

                  <Hidden mdDown>
                    <Typography variant={'h2'} style={{fontStyle: 'italic'}} >
                      “Los detalles en la ejecución se reflejan en la calidad y tiempos de los trabajos”.
                    </Typography>
                  </Hidden>

                  <Box className={classes.badge}/>
                </Box>
                <Typography variant={'h3'} style={{fontStyle: 'italic'}}>
                  Luis Esqueda
                </Typography>
              </Box>
            </Box>
          </Box>
          <img src={buildingD} style={{width: '100%'}}/>
        </Box>
        <Typography variant={'h3'} style={{marginTop:24}}>
          Ellos han confiado en nosotros
        </Typography>
        <Box className={classes.sectionsContainer} style={{justifyItems:'center'}}>
          {
            images.map((image)=><Box>
              <img src={image} style={{filter: 'invert(100%)'}}/>
            </Box>)
          }

        </Box>
        <Footer/>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    rootContainer: {
      backgroundColor: 'black',
      height: '100%'
    },
    backgroundContainer: {
      backgroundImage: `url(${city})`,
      height: '100%',
      width: '100%',
      filter: 'opacity(15%)',
      position: 'absolute',
      overflowY: 'auto'
    },
    mainContainer: {
      position: 'absolute',
      height: '100%',
      overflowY: "auto",
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: '2',
    },
    servicesGridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
      gridTemplateRows: 'auto auto',
      gap: '1rem',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
      }
    },
    badge: {
      width: '1rem',
      height: '8rem',
      backgroundColor: '#9AD7F7',
      [theme.breakpoints.down('sm')]: {
        width: '.5rem',
      }
    },
    horizontalBadge: {
      width: '8rem',
      height: '.1rem',
      backgroundColor: '#9AD7F7',

    },
    description: {
      fontSize: '12em'
    },
    sectionsContainer: {
      display: 'flex',
      height: '90vh',
      width: '80%',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        marginTop: 24,
        marginBottom: 24,
        alignItems: 'flex-start',
        height: '100%',
      },
    },
    buildings: {
      objectFit: 'cover',
      objectPosition: '-20% 0',
      width: '15vw',
      height: '50vh',
      [theme.breakpoints.down('md')]: {
        flexDirection:'row',
        width: '50%',
      },
    },
    whatWeAreWorkingContainer: {
      padding: '1rem',
      marginBottom: '2rem',
      alignSelf: 'flex-end',
      borderBottom: 'white .3rem solid',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'center',
      }
    },
    projectsTypeContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
      marginTop: '3rem',
      width: '90%',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gridGap:'3rem'
      },
    },
    projectsTypeItem: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
       flexDirection:'row-reverse'
      },
    },
    slogan:{
      width: '40%',
      [theme.breakpoints.down('sm')]: {
        width: '60%'
      },
    },
    cardsContainer:{
      display: 'flex',
      height: '70vh',
      width: '80%',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: 24,
        marginBottom: 24,
        alignItems: 'flex-center',
        height: '100%',
      },
    },
  }
})

export default Home
